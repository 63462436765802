import { Injectable } from '@angular/core';

import { DataStoreKey, DataStoreService } from './data-store.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  preference: any = {};
  constructor(private dss: DataStoreService) {}

  async loadPreference() {
    const settings = await this.dss.loadFromDevice(DataStoreKey.SETTINGS);
    console.log('Settings:', settings);
    this.preference = settings.value; // JSON.parse(settings.value);
  }

  async savePreferenceDarkTheme(darkTheme: boolean) {
    this.preference = { ...this.preference, darkTheme };
    return await this.dss.saveToDevice(DataStoreKey.SETTINGS, JSON.stringify(this.preference));
  }
}
