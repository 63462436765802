import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

import { environment } from './../environments/environment';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss', 'corner-ribbons.scss'],
})
export class AppComponent {
  devEnvironment = environment.dev;
  // uatEnvironment = environment.uat;
  demoEnvironment = environment.demo;

  constructor(private platform: Platform, private settings: SettingsService, private afs: AngularFirestore) {
    this.initializeApp();
  }

  initializeApp() {
    this.afs.firestore
      .enablePersistence()
      .then(() => {
        console.log('READY! DB Persistence is enabled to support offline mode');
      })
      .catch((error) => {
        console.error('ERROR! Could not enable DB Persistence!');
      });
    this.platform.ready().then((readySource) => {
      console.log('Platform is now ready:', readySource);
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        setTimeout(() => {
          SplashScreen.hide({ fadeOutDuration: 1200 });
        }, 2500);
      }

      this.settings.loadPreference().then(() => {
        // Check if local preferences are set for darkTheme else use system prefers
        const hasUserPreference = this.settings.preference ? this.settings.preference.darkTheme : null;
        let darkTheme = false;
        if (hasUserPreference !== null) {
          // User has a preference to override
          darkTheme = hasUserPreference;
        } else {
          // Check if the prefers-color-scheme: dark & set prefersDark
          // The same should be connected with settings Toggle Dark Theme
          const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
          this.settings.savePreferenceDarkTheme(prefersDark.matches);
          darkTheme = prefersDark.matches;
        }
        darkTheme = false; // TODO: Remove this override
        document.body.classList.toggle('dark', darkTheme);
      });

      // console.log('[AppComponent] User on Device:', this.auth.getUserOnDevice());
    });
  }
}
