import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
// import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
// import { Plugins } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const Storage = Preferences;

/*
Objectives:
1. Ensure the userOnDevice is in local storage
2. Signup use case - update the Display Name to user Profile only after validating OTP
3. Signin use case - update the displayName if there is a change
4. Let AuthGuard (outside AuthService) take care of routing permissions - avoid using Router in Auth Service
*/

@Injectable({ providedIn: 'root' })
export class AuthService {
  private verificationId!: string | null;
  private user: Subject<any> = new BehaviorSubject<any>(null);
  private userOnDevice: any;
  private USER_ON_DEVICE = 'user_on_device';
  private displayName!: string | null;
  private phoneNumber!: string | null;

  private attorneyInvitation: any;
  private providerInvitation: any;
  private windowRef: any;

  constructor(
    // private fbAuth: FirebaseAuthentication,
    private platform: Platform,
    private ngFire: AngularFirestore,
    private ngAuth: AngularFireAuth
  ) {
    // First load if there is any user on device
    this.loadUserOnDevice().then(() => {
      this.ngAuth.onAuthStateChanged((userInfo) => {
        console.log('[AuthService] ngAuth authState:', userInfo);
        // const loggedIn = Boolean(userInfo && userInfo.uid);
        if (userInfo && userInfo.uid) {
          this.userOnDevice = {
            ...this.userOnDevice,
            displayName: this.displayName || userInfo.displayName,
            photoURL: userInfo.photoURL,
            phoneNumber: userInfo.phoneNumber || '',
            providerId: userInfo.providerId,
            email: userInfo.email,
            emailVerified: userInfo.emailVerified,
            uid: userInfo.uid,
            loggedIn: Boolean(userInfo && userInfo.uid),
          };
          this.phoneNumber = userInfo.phoneNumber;
          this.updateUserOnDevice();
        } else {
          this.userOnDevice = { ...this.userOnDevice, loggedIn: false };
        }
        console.log('[AuthService] user:', this.userOnDevice);
        this.user.next(Boolean(userInfo && userInfo.uid) ? this.userOnDevice : null);
      });
    });
  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  async validateInvitation(phoneNumber: string): Promise<boolean> {
    // TODO: Remove temporary bypass and fix with an approved solution
    return Promise.resolve(true);
    if (this.isValidPhoneNumber(phoneNumber)) {
      // For a valid phone number, check if the Attorney or the Provider has sent an invite
      const attorneyInvite = await this.checkAttorneyInvite(phoneNumber);
      const providerInvite = await this.checkProviderInvite(phoneNumber);
      const inviteValid =
        (attorneyInvite && attorneyInvite?.docs && attorneyInvite?.docs.length) ||
        // (providerInvite && providerInvite && providerInvite.length);
        (providerInvite && providerInvite?.docs && providerInvite?.docs.length);
      if (inviteValid) {
        return Promise.resolve(true);
      } else {
        return Promise.reject('Missing invite for the Phone Number!');
      }
    }
    return Promise.reject('Invalid Phone Number!');
  }

  async verifyPhoneNumber(phoneNumber: string, name?: string): Promise<any> {
    // Ensure the phone number is as per E.164 definition
    if (!this.isValidPhoneNumber(phoneNumber)) {
      return Promise.reject('Invalid phone number!');
    }
    this.phoneNumber = phoneNumber;
    // Update user displayName captured from name property
    if (name && name.length > 2) {
      this.displayName = name;
    }

    // [Outside scope] Ensure Attorney App / Provider App - create the invite entries forehand for user to sign up
    // Check if the phone number is part of the invites to join the Attorney service
    const attorneyInvite = await this.checkAttorneyInvite(phoneNumber);
    console.log('[AuthService] Attorney invite:', attorneyInvite?.docs[0]?.data());

    // Check if the phone number is part of the invites to join the Provider service
    const providerInvite = await this.checkProviderInvite(phoneNumber);
    console.log('[AuthService] Provider invite:', providerInvite?.docs[0]?.data());

    const inviteValid =
      (attorneyInvite && attorneyInvite.docs && attorneyInvite.docs.length) ||
      // (providerInvite && providerInvite && providerInvite.length);
      (providerInvite && providerInvite.docs && providerInvite.docs.length);

    // if (inviteValid) {
    // Capture the invitation details (from, orgCode, type etc) - and update userOnDevice with connection object
    this.attorneyInvitation = attorneyInvite?.docs[0]?.data() || null;
    console.log('[AuthService] attorneyInvitation:', this.attorneyInvitation);
    // TODO: What if there are more than one Provider Invitations? Why pick the first one only?
    this.providerInvitation = providerInvite?.docs[0]?.data() || null;
    console.log('[AuthService] providerInvitation:', this.providerInvitation);

    // Add the attorney & provider invite references to the userOnDevice
    // userOnDevice gets updated with last login to /client-users/{uid} node on FS for every successful login
    this.userOnDevice = {
      ...this.userOnDevice,
      attorney: this.userOnDevice.attorney || this.attorneyInvitation,
      provider: this.userOnDevice.provider || this.providerInvitation,
    };
    await this.updateUserOnDevice();
    console.log('[AuthService] platform hybrid?:', this.platform.is('hybrid'));
    // TODO: auto retrieve the SMS Code and link it to onAuthStateChanged()
    if (this.platform.is('hybrid')) {
      // return this.fbAuth
      //   .verifyPhoneNumber(phoneNumber, 120)
      //   .then((verificationId: string) => {
      //     this.verificationId = verificationId;
      //     console.log('Got verficationId:', verificationId);
      //   })
      //   .catch((error: any) => {
      //     console.log('Error getting OTP:', error);
      //     return Promise.reject('Sorry! Could not get OTP');
      //   });
    } else {
      // For Dev-Testing on browser
      this.setupRecaptcha();
      return this.signInWithPhoneNumber(phoneNumber, this.windowRef.recaptchaVerifier).then((result) => {
        // This is connected with ReCaptchaVerifier setup, upon successful result will proceed as per setup
        this.windowRef.confirmationResult = result;
      });
    }
    // } else {
    //   return Promise.reject('Invalid phone number or missing invitation!');
    // }
  }

  async verifyOTP(smsCode: string): Promise<any> {
    if (!this.platform.is('hybrid')) {
      return this.verifyLoginCode(smsCode, this.windowRef.confirmationResult);
    }
    const credential = firebase.auth.PhoneAuthProvider.credential(this.verificationId || '', smsCode);
    // console.log('[AuthService] verifyOTP(): credential = ', credential);
    return this.ngAuth.signInWithCredential(credential);
    // After this action the authStateChanged() will take over the user login
  }

  isValidPhoneNumber(phoneNumber: string): boolean {
    const regex = /^\+[1-9]\d{10,14}$/;
    return regex.test(phoneNumber);
  }

  signOut() {
    return this.ngAuth.signOut();
  }

  // signIn(email: string, password: string): Promise<any> {
  //   return this.ngAuth.signInWithEmailAndPassword(email, password);
  // }

  // createUser(email: string, password: string): Promise<any> {
  //   return this.ngAuth.createUserWithEmailAndPassword(email, password);
  // }

  // sendResetPasswordEmail(email: string): Promise<any> {
  //   return this.ngAuth.sendPasswordResetEmail(email);
  // }

  // TODO: implement email verification
  // sendVerificationEmail(): Promise<any> {
  //   return this.fbAuth.sendEmailVerification();
  // }

  getUserOnDevice(): any {
    return this.userOnDevice || null;
  }

  reloadUserOnDevice() {
    return this.loadUserOnDevice();
  }

  getAttorneyInvitation(): any {
    if (!this.attorneyInvitation) {
      this.attorneyInvitation = this.userOnDevice.attorney;
    }
    return this.attorneyInvitation;
  }

  getProviderInvitation(): any {
    if (!this.providerInvitation) {
      this.providerInvitation = this.userOnDevice.provider;
    }
    return this.providerInvitation;
  }

  private async checkAttorneyInvite(phoneNumber: string) {
    // TODO: Get this path from a path definition model
    const path = 'client-invitations';
    return this.ngFire
      .collection(path, (ref) => ref.where('phoneNumber', '==', phoneNumber))
      .get()
      .toPromise();
  }

  private async checkProviderInvite(phoneNumber: string) {
    // TODO: Get this path from a path definition model
    const path = 'patient-invitations';
    console.log('Checking Patient Invitations!');
    return this.ngFire
      .collection(path, (ref) => ref.where('phoneNumber', '==', phoneNumber))
      .get()
      .toPromise();
  }

  private async loadUserOnDevice() {
    const userOnStorage = '' + (await Storage.get({ key: this.USER_ON_DEVICE }));
    try {
      this.userOnDevice = JSON.parse(userOnStorage);
    } catch (error) {
      this.userOnDevice = null;
    }
    console.log('[AuthService] loadUserOnDevice(): userOnDevice=', this.userOnDevice);
  }

  // Use the local storage to save the user info & logged in state
  private async updateUserOnDevice() {
    // If the 'name' doesn't match with the displayName from the fb auth, then update displayName
    console.log('[AuthService] updateUserOnDevice(): userOnDevice=', this.userOnDevice);
    const cUser = await this.ngAuth.currentUser;
    if (cUser && this.displayName && cUser.displayName !== this.displayName) {
      console.log('[AuthService] updateUserOnDevice(): Updating User Profile with name:', this.displayName);
      cUser.updateProfile({ displayName: this.displayName });
    }
    console.log('[AuthService] updateUserOnDevice(): userOnDevice=', this.userOnDevice, this.phoneNumber);
    Storage.set({ key: this.USER_ON_DEVICE, value: JSON.stringify(this.userOnDevice) });
  }

  // For dev testing on browser only - not used on mobile device
  private signInWithPhoneNumber(phone: string, appVerifier: firebase.auth.ApplicationVerifier): Promise<any> {
    return this.ngAuth.signInWithPhoneNumber(phone, appVerifier);
    // return firebase.auth().signInWithPhoneNumber(phone, appVerifier);
  }

  private verifyLoginCode(passcode: string, confirmationResult: any): Promise<any> {
    return confirmationResult.confirm(passcode);
  }

  private setupRecaptcha(): void {
    this.windowRef = window;
    if (!this.windowRef.recaptchaVerifier) {
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        size: 'invisible',
        callback: (response: any) => {
          console.log('Recaptcha Verifier response = ', response);
          // Once the ReCaptcha is solved with a response, move to next step of login process
        },
        'expired-callback': () => {
          console.log('Recaptcha Verifier expired. User has to click the button again.');
        },
      });
    }
  }
}
