import { AuthService } from './auth.service';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// import { Plugins } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';

const Storage = Preferences;

export enum DocPath {
  INTAKE,
  SETTINGS,
  PROFILE,
}
const docPaths = ['user-intake', 'user-device-settings', 'user-profile'];

export enum ColPath {
  PHOTOS,
  DOCUMENTS,
  APPOINTMENTS,
  TASKS,
  ATTORNEY_REFERRALS,
  PROVIDER_REFERRALS,
}
const colPaths = [
  'user-photos',
  'user-documents',
  'user-appointments',
  'user-tasks',
  'attorney-referrals',
  'provider-referrals',
];
// NOTE: Keep the DocPath <=> docPaths and ColPath <=> colPaths mapping order in sync

// Use DataStore enumeration paths to map the local device storage keys
export enum DataStoreKey {
  INTAKE,
  SETTINGS,
  PROFILE,
  PHOTOS,
  TASKS,
  DOCUMENTS, // Use this to store pdf documents locally (only for templates?)
  USER_ON_DEVICE,
}

const dataStoreKeys = ['intake_data', 'settings', 'profile', 'photos', 'tasksStatus', 'documents', 'user_on_device'];

/*
  const DB_COLLECTIONS = {
    photos: 'user-photos',
    documents: 'user-documents',
    appointments: 'user-appointments',
    tasks: 'user-tasks',
  };

  const DB_DOCUMENT = {
    intake: 'user-intake',
    settings: 'user-device-settings',
    profile: 'user-profile',
  };
*/

@Injectable({ providedIn: 'root' })
export class DataStoreService {
  private uid!: string;
  private basePath!: string | null;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private authService: AuthService) {
    this.authService.getUser().subscribe((u) => {
      this.uid = u?.uid;
      this.basePath = this.uid ? `/client-users/${this.uid}` : null;
      if (u && u.uid) {
        // Everytime the user has logged in, update the Attorney Org Code and users Invited Phone number
        // This can be used in Cloud Functions to match the Attorney Org's Plaintiff Account
        const attorneyOrgCode = u.attorney?.orgCode || null;
        const providerOrgCode = u.provider?.orgCode || null;
        const phoneNumber = u.phoneNumber;
        const displayName = u.displayName;
        // TODO: Is this good to update Client User's phone number which the client has used to login with?
        if (this.basePath) {
          this.db.doc(this.basePath).set(
            {
              attorneyOrgCode,
              providerOrgCode,
              phoneNumber,
              displayName,
              uid: this.uid,
              _lastLogin: new Date().toISOString(),
            },
            { merge: true }
          );
        }
      }
    });
  }

  getCollectionPath(collectionPath: ColPath) {
    return this.uid ? `${this.basePath}/${colPaths[collectionPath]}` : null;
  }

  getUserPath() {
    return this.uid ? `client-users/${this.uid}` : null;
  }

  readCollection(collectionPath: ColPath, query?: QueryFn): Observable<any[]> {
    if (!this.uid) {
      return of([]);
    }
    const path = `${this.basePath}/${colPaths[collectionPath]}`;
    console.log('[DataStoreService] readCollection() path =', path);
    return this.db.collection(path, query).valueChanges({ idField: 'id' });
  }

  addToCollection(collectionPath: ColPath, document: any): Promise<any> {
    if (!this.uid) {
      return Promise.reject(null);
    }
    const path = `${this.basePath}/${colPaths[collectionPath]}`;
    console.log('[DataStoreService] addToCollection() path =', path);
    return this.db.collection(path).add(document);
  }

  readDocument(documentPath: DocPath): Observable<any> {
    if (!this.uid) {
      return of([]);
    }
    const path = `${this.basePath}/doc-paths/${docPaths[documentPath]}`;
    return this.db.doc(path).valueChanges();
  }

  updateDocument(documentPath: DocPath, updateObj: any): Promise<any> {
    if (!this.uid) {
      return Promise.reject('ERROR! Missing UID');
    }
    const path = `${this.basePath}/doc-paths/${docPaths[documentPath]}`;
    return this.db.doc(path).set({ ...updateObj, ...this.getUpdateTimestamp() }, { merge: true });
  }

  update(path: string, updateObj: any): Promise<any> {
    if (!this.uid) {
      return Promise.reject('ERROR! Missing UID');
    }
    return this.db.doc(path).set({ ...updateObj, ...this.getUpdateTimestamp() }, { merge: true });
  }

  deleteDocument(documentPath: DocPath): Promise<any> {
    if (!this.uid) {
      return Promise.reject('ERROR! Missing UID');
    }
    const path = `${this.basePath}/doc-paths/${docPaths[documentPath]}`;
    return this.db.doc(path).delete();
  }

  delete(path: string): Promise<any> {
    if (!this.uid) {
      return Promise.reject('ERROR! Missing UID');
    }
    return this.db.doc(path).delete();
  }

  async uploadFile(
    targetPath: string,
    content: File | string,
    contentType = 'image/jpeg'
  ): Promise<{ downloadURL: string; path: string }> {
    if (!targetPath || targetPath.includes('_')) {
      return Promise.reject('ERROR! Invalid storage target path');
    }
    if (!this.uid) {
      return Promise.reject('ERROR! Missing UID');
    }
    const refPath = `${this.basePath}/${contentType === 'image/jpeg' ? 'photos' : 'documents'}/${targetPath}`;
    const ref = this.storage.ref(refPath);
    return new Promise(async (resolve, reject) => {
      try {
        if (typeof content === 'string') {
          console.log('[DataStoreService] uploadFile(): (String) refPath=', refPath, contentType);
          await ref.putString(content, 'base64', { contentType });
        } else {
          console.log('[DataStoreService] uploadFile(): (File) refPath=', refPath, contentType);
          await ref.put(content, { contentType });
        }
        ref.getDownloadURL().subscribe((downloadURL) => {
          resolve({ downloadURL, path: refPath });
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async loadFromDevice(key: DataStoreKey) {
    return Storage.get({ key: dataStoreKeys[key] });
  }

  async saveToDevice(key: DataStoreKey, data: any) {
    return Storage.set({ key: dataStoreKeys[key], value: data });
  }

  async removeFromDevice(key: DataStoreKey) {
    return Storage.remove({ key: dataStoreKeys[key] });
  }

  getUpdateTimestamp() {
    return { updatedAt: firebase.firestore.FieldValue.serverTimestamp() };
  }
}
