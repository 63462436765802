export const devConfig = {
  apiKey: 'AIzaSyBHprQLQ2TFpeK1W3quwnhwEPZExT7nTP0', // android key - TODO: apply restrictions on console
  authDomain: 'mlc-dev.firebaseapp.com',
  databaseURL: 'https://mlc-dev.firebaseio.com',
  projectId: 'mlc-dev',
  storageBucket: 'mlc-dev.appspot.com',
  appId: '1:526507237982:android:bb738d74a4b3a8f5908acf',
};

export const demoConfig = {
  apiKey: 'AIzaSyBcQsp_6jN0pOs7HaoUZZeYvIf3zesOd2I', // android key - TODO: apply restrictions on console
  authDomain: 'mlc-demo.firebaseapp.com',
  databaseURL: 'https://mlc-demo-default-rtdb.firebaseio.com',
  projectId: 'mlc-demo',
  storageBucket: 'mlc-demo.appspot.com',
  appId: '1:1092256480351:android:8b6463bf963d0de1c4afea',
};

// Updated Prod config
export const prodConfig = {
  apiKey: 'AIzaSyDy-nBm0l6SAHxGxCXD_E2AVJJltUxs0v8', // android key - TODO: apply restrictions on firebase console
  authDomain: 'prod-mlc.firebaseapp.com',
  databaseURL: 'https://prod-mlc-default-rtdb.firebaseio.com',
  projectId: 'prod-mlc',
  storageBucket: 'prod-mlc.appspot.com',
  appId: '1:964636746264:android:9af44cf0850de088ee196d',
};

export const devPWAConfig = {
  apiKey: 'AIzaSyC0TTta5egRJ0LxDSeEvd8BhLrGG9IPQRk',
  authDomain: 'mlc-dev.firebaseapp.com',
  databaseURL: 'https://mlc-dev.firebaseio.com',
  projectId: 'mlc-dev',
  storageBucket: 'mlc-dev.appspot.com',
  messagingSenderId: '526507237982',
  appId: '1:526507237982:web:7df767608c3f23b1908acf',
  measurementId: 'G-C4JJP2CKW8',
};

export const demoPWAConfig = {
  apiKey: 'AIzaSyBcQsp_6jN0pOs7HaoUZZeYvIf3zesOd2I',
  authDomain: 'mlc-demo.firebaseapp.com',
  databaseURL: 'https://mlc-demo-default-rtdb.firebaseio.com',
  projectId: 'mlc-demo',
  storageBucket: 'mlc-demo.appspot.com',
  messagingSenderId: '1092256480351',
  appId: '1:1092256480351:web:124ad43580038634c4afea',
  measurementId: 'G-6DNXDXWC5Q',
};

export const prodPWAConfig = {
  apiKey: 'AIzaSyBc_bgXKqLhW6O4a2Ep2XAV2uYJ-IxLKAA',
  authDomain: 'prod-mlc.firebaseapp.com',
  databaseURL: 'https://prod-mlc-default-rtdb.firebaseio.com',
  projectId: 'prod-mlc',
  storageBucket: 'prod-mlc.appspot.com',
  messagingSenderId: '964636746264',
  appId: '1:964636746264:web:04790e03291bba72ee196d',
  measurementId: 'G-9ZKRBJPSK1',
};
